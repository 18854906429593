import Bowser from 'bowser'

import { MIC_PERMISSIONS_DENIED, MIC_PERMISSIONS_GRANTED, MIC_PERMISSIONS_PROMPT } from '../components/Recorder/recordings'

export enum BrowserType {
  OPERA,
  FIREFOX,
  SAFARI,
  INTERNET_EXPLORER,
  EDGE,
  CHROME,
  // depreacted, do not use
  EDGE_CHROMIUM,
  BLINK,
  NOT_SUPPORTED,
}

export type PermissionResponse = PermissionState | 'could-not-check'

export class BrowserUtils {
  public static isMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera
    const regex1 =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
    const regex2 =
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i

    return regex1.test(userAgent) || regex2.test(userAgent.substr(0, 4))
  }

  public static getBrowserName(): string {
    const userAgent = Bowser.getParser(window.navigator.userAgent)
    return userAgent.getBrowserName()
  }

  public static getBrowserVersion(): string {
    const userAgent = Bowser.getParser(window.navigator.userAgent)
    return userAgent.getBrowserVersion()
  }

  /**
   * Gets the name of the browser the user is on.
   * The order of the checks are from most popular browser to least popular for MICRO SECOND time savings. OH YEAH
   * @Return BrowserType The BrowserType enum corresponding the to the user's browser.
   */
  public static getBrowser(): BrowserType {
    const userAgent = Bowser.getParser(window.navigator.userAgent)

    const browserName = userAgent.getBrowserName()

    switch (browserName) {
      case 'Safari':
        return BrowserType.SAFARI
      case 'Firefox':
        return BrowserType.FIREFOX
      case 'Internet Explorer':
        return BrowserType.INTERNET_EXPLORER
      case 'Microsoft Edge':
        return BrowserType.EDGE
      case 'Chrome':
      case 'Chromium':
        return BrowserType.CHROME
      case 'Opera':
        return BrowserType.OPERA
      default:
        // Edge (chromium fork) detection
        // @ts-ignore
        if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && navigator.userAgent.indexOf('Edg') != -1) {
          return BrowserType.EDGE_CHROMIUM
        }

        // Blink engine detection
        // prettier-ignore
        // @ts-ignore
        if ((((!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime))) || (((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0))) && !!window.CSS) {
          return BrowserType.BLINK
        }

        return BrowserType.NOT_SUPPORTED
    }
  }

  /**
   * Helper method to check microphone permissions using the experimental Permission API or the polyfill.
   * @param permissionsOnChange Callback function taking 1 parameter of type PermissionResponse. Will be called if
   * the microphone permissions change at any time with the status of the microphone permissions
   * @return Promise resolving to either PROMPTED, DENIED or GRANTED. Rejects if could not check the microphone
   * permissions using the permissions API.
   */
  public static checkMicrophonePermissions(permissionsOnChange?: (permissionResponse: PermissionResponse) => void, usePolyfillFallback = true) {
    return new Promise<PermissionResponse>((resolve, reject) => {
      this.checkMicrophonePermissionsUsingPermissionsAPI(permissionsOnChange)
        .then((permissionResponse) => {
          resolve(permissionResponse)
        })
        .catch(() => {
          if (usePolyfillFallback) {
            this.checkMicrophonePermissionPolyFill()
              .then((permissionResponse) => {
                resolve(permissionResponse)
              })
              .catch((permissionResponse) => {
                reject(permissionResponse)
              })
          } else {
            reject('could-not-checkk')
          }
        })
    })
  }

  /**
   * Internal method to check microphone permissions using the experimental permissions API.
   * @param permissionsOnChange Callback function taking 1 parameter of type PermissionResponse. Will be called if
   * the microphone permissions change at any time with the status of the microphone permissions.
   * @return Promise resolving to either PROMPTED, DENIED or GRANTED. Rejects if could not check the microphone
   * permissions using the permissions API.
   */
  private static checkMicrophonePermissionsUsingPermissionsAPI(permissionsOnChange?: (permissionResponse: PermissionResponse) => void) {
    return new Promise<PermissionResponse>((resolve, reject) => {
      if (navigator.permissions == undefined || this.getBrowser() == BrowserType.FIREFOX) {
        reject('could-not-check')
      }
      navigator.permissions
        .query({
          name: 'microphone' as PermissionName,
        })
        .then((permissionStatus) => {
          resolve(permissionStatus.state)

          // //Hook the permission status on change method
          permissionStatus.onchange = () => {
            if (permissionsOnChange) {
              if (permissionStatus.state === MIC_PERMISSIONS_DENIED) permissionsOnChange('denied')
              else if (permissionStatus.state === MIC_PERMISSIONS_GRANTED) permissionsOnChange('granted')
              else if (permissionStatus.state === MIC_PERMISSIONS_PROMPT) permissionsOnChange('prompt')
            }
          }
        })
        .catch(() => {
          reject('could-not-check')
        })
    })
  }

  /**
   * Polyfill to check microphone permissions for browsers not implementing the permissions API.
   * @return Promise resolving to either GRANTED or DENIED, rejects if permissions could not be checked.
   */
  private static checkMicrophonePermissionPolyFill() {
    return new Promise<PermissionResponse>((resolve, reject) => {
      if (navigator.mediaDevices == undefined) {
        reject('could-not-check')
        return
      }
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function () {
          resolve('granted')
        })
        .catch(function () {
          resolve('denied')
        })
    })
  }
}

// returns a promise that resolves after the JavaScript event loop
// has processed any other events waiting in the queue, allowing other tasks to complete
export function yieldToMain() {
  return new Promise((resolve) => {
    setTimeout(resolve, 0)
  })
}

/**
 * This function tests whether a given string is made up entirely of special characters,
 * and returns `true` if it is and `false` if it is not.
 *
 * Special characters are defined as any character that is not a letter, number, whitespace,
 * or emoji from any language or script.
 *
 * @param {string} string - The string to be tested.
 *
 * @returns {boolean} - `true` if the string is made up entirely of special characters,
 *                      `false` otherwise.
 *
 * The regular expression used in this function, /[\p{L}\p{N}\p{Z}\p{Emoji}]/gu, is broken
 * down as follows:
 *
 * - \p{L} matches any kind of letter from any language.
 * - \p{N} matches any kind of numeric character in any script.
 * - \p{Z} matches any kind of whitespace or invisible separator.
 * - \p{Emoji} matches any emoji character.
 *
 * The enclosing brackets [] create a character set, matching any character that is in the set.
 *
 * The 'g' flag enables global matching, so the test doesn't stop at the first match.
 * The 'u' flag enables full Unicode matching, which allows the \p{} Unicode property escapes
 * to match characters from any language or script.
 *
 * The test() method tests the string against the regular expression and returns `true` if
 * the string contains at least one matching character and `false` if it doesn't.
 *
 * The `!` operator negates the result of the test() method, so the function returns `true` if
 * test() returns `false` and vice versa.
 *
 */
export function containsOnlySpecialChar(text: string): boolean {
  const regex = /[\p{L}\p{N}\p{Z}\p{Emoji}]/gu
  return !regex.test(text)
}
